export default class Country {
  readonly id: string;
  readonly iso: string;
  readonly name: string;
  readonly currency: string;
  readonly idTypes: IdType[];

  constructor(args: { id: string; iso: string; name: string; currency: string; idTypes: IdType[] }) {
    this.id = args.id;
    this.iso = args.iso;
    this.name = args.name;
    this.currency = args.currency;
    this.idTypes = args.idTypes;
  }

  static search(identifier: string) {
    const list = [countryGhana];

    return list.filter((country) => {
      return (
        identifier === country.id ||
        identifier === country.name ||
        identifier === country.iso ||
        identifier === country.currency
      );
    });
  }

  static find(identifier: string) {
    const results = this.search(identifier);
    if (results[0]) return results[0];
    return null;
  }
}

export interface IdType {
  readonly name: string;
  readonly code: string;
}

export const countryGhana = new Country({
  id: "GH",
  iso: "GH",
  name: "Ghana",
  currency: "GHS",
  idTypes: [
    { name: "Ghana Card", code: "IDENTITY_CARD" },
    { name: "Passport", code: "PASSPORT" }
    // { name: "Driver's License", code: "DRIVERS_LICENSE" }
  ]
});
