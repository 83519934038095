import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  size?: "lg" | "xl";
  align?: "center" | "right";
  inline?: boolean;
}

export const Title = forwardRef<HTMLHeadingElement, TitleProps>(
  ({ size, align, className, inline, ...rest }: TitleProps, ref) => {
    const Comp = inline ? "span" : "h1";

    const _className = mergeClasses(
      "font-bold",
      "leading-normal",
      !size && "text-[20px]",
      size === "lg" && "text-[28px]",
      size === "xl" && "text-[40px]",
      align === "center" && "text-center",
      align === "right" && "text-right",
      inline && "inline",
      className
    );

    return <Comp ref={ref} className={_className} {...rest} />;
  }
);
