import { ButtonHTMLAttributes, forwardRef } from "react";
import { mergeClasses } from "@/utils/common";
import { Spinner } from "@/components/common/spinner";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "secondary" | "link";
  size?: "sm" | "lg";
  loading?: boolean;
  flex?: boolean;
  borderless?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { variant, type = "button", className, size, children, disabled, loading, flex, borderless, ...rest }: ButtonProps,
    ref
  ) => {
    const _className = mergeClasses(
      "inline-flex",
      "justify-center",
      "gap-2",
      "items-center",
      "rounded-full",
      "uppercase",
      "font-[500]",
      "tracking-wider",
      "hover:enabled:opacity-80",
      "active:enabled:scale-[98%]",
      "disabled:opacity-70",
      "focus:outline-0",
      "focus:ring-0",
      flex && "w-full",
      !variant && "bg-primary text-white", // Default variant classes
      variant === "secondary" && "bg-transparent text-primary border-2 border-primary",
      variant === "link" && "bg-transparent text-primary !capitalize",
      !size && "px-5 py-3 text-[14px]", // Default size classes
      size === "sm" && "text-[12px] px-3 py-2",
      size === "lg" && "text-[16px] px-8 py-4",
      (disabled || loading) && "!opacity-25",
      borderless && "!border-none",
      className
    );

    return (
      <button ref={ref} type={type} className={_className} disabled={disabled || loading} {...rest}>
        {loading ? <Spinner color={!variant ? "white" : undefined} size={18} inline /> : children}
      </button>
    );
  }
);
