import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

export const PopupMenu = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...rest }: HTMLAttributes<HTMLDivElement>, ref) => {
    const _className = mergeClasses(
      "w-64",
      "bg-white",
      "shadow-2xl",
      "overflow-hidden",
      "flex",
      "flex-col",
      "rounded-xl",
      "border-2",
      "border-gray-900",
      className
    );

    return <div ref={ref} className={_className} {...rest}></div>;
  }
);
