import { forwardRef, useEffect, useState } from "react";
import Image, { ImageProps } from "next/image";

interface ImgProps extends Omit<ImageProps, "src"> {
  src: string | Promise<string>;
}

export const Img = forwardRef<HTMLImageElement, ImgProps>(({ src, alt, width, height, ...rest }: ImgProps, ref) => {
  const [_src, setSrc] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (src instanceof Promise) {
      src.then((src) => setSrc(src)).finally(() => setLoading(false));
      setLoading(true);
    } else setSrc(src);
  }, [src]);

  if (loading) {
    return (
      <div className="bg-gray-200 animate-pulse" style={{ width: `${width || 0}px`, height: `${height || 0}px` }}></div>
    );
  }

  return <Image ref={ref} src={_src || "placeholder"} alt={alt} width={width || 0} height={height || 0} {...rest} />;
});
