import { createContext, ReactNode, useContext, useState } from "react";

interface MemoryContext {
  memGet: <T>(key: string) => T | undefined;
  memSet: (key: string, data: any) => void;
}

const MemoryContext = createContext<MemoryContext>(null as never);

export const useMemoryContext = () => useContext(MemoryContext);

export const MemoryProvider = (props: { children: ReactNode }) => {
  const [store, setStore] = useState<{ [k: string]: any }>({});

  const memGet = <T,>(key: string) => {
    return store[key] as T | undefined;
  };

  const memSet = (key: string, data: any) => {
    setStore((store) => ({ ...store, [key]: data }));
  };

  return <MemoryContext.Provider value={{ memGet, memSet }}>{props.children}</MemoryContext.Provider>;
};
