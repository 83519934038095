import { ReactNode, useEffect } from "react";
import { mergeClasses } from "@/utils/common";
import { createPortal } from "react-dom";
import Link from "next/link";

interface OverlayProps {
  show: boolean;
  placement?: "top" | "center" | "bottom";
  children: ReactNode;
  onClose?: string | (() => void);
}

export const Overlay = ({ show, children, placement = "bottom", onClose }: OverlayProps) => {
  const overlayClassName = mergeClasses(
    "fixed",
    "top-0",
    "left-0",
    "w-screen",
    "hd-screen",
    "overflow-hidden",
    "flex",
    "flex-col",
    "z-[100]",
    placement === "top" && "justify-start",
    placement === "center" && "justify-center",
    placement === "bottom" && "justify-end"
  );

  const overlayBackdropClassName = mergeClasses(
    "absolute",
    "top-0",
    "left-0",
    "w-screen",
    "hd-screen",
    "bg-black/50",
    "backdrop-blur",
    "-z-[1]"
  );

  const overlayContentHolderClassName = mergeClasses("max-h-[100%]", "overflow-auto");

  useEffect(() => {
    if (show) document.body.classList.add("overflow-hidden");
    else document.body.classList.remove("overflow-hidden");
  }, [show]);

  if (show) {
    return createPortal(
      (
        <div className={overlayClassName}>
          {typeof onClose === "string" ? (
            <Link href={onClose} className={overlayBackdropClassName} />
          ) : (
            <div className={overlayBackdropClassName} onClick={onClose} />
          )}

          <div className={overlayContentHolderClassName}>{children}</div>
        </div>
      ) as never,
      document.body
    );
  }

  return null;
};
