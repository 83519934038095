import { generate } from "randomstring";
import { NextRouter } from "next/router";

export const mergeClasses = (...classes: (string | boolean | null | undefined)[]) => {
  return classes.filter(Boolean).join(" ");
};

export const generateId = (prefix: string) => {
  const trimmedPrefix = prefix.replace(/[^a-zA-Z0-9]+/g, "_");
  const randId = generate({ charset: "alphanumeric", length: 8 });
  const id = trimmedPrefix.endsWith("_") ? `${trimmedPrefix}${randId}` : `${trimmedPrefix}_${randId}`;
  return id.toLowerCase();
};

export const updateRouterQuery = (
  router: NextRouter,
  query: Record<string, string | null | undefined>,
  options?: { replace?: boolean }
) => {
  if (options?.replace) void router.replace({ query: { ...router.query, ...query } });
  void router.push({ query: { ...router.query, ...query } });
};
