import Country, { IdType } from "./country";

interface User {
  id: string;
  status: UserStatus;
  name: string;
  email: string;
  phone: string;
  country: Country;
  address?: string;
  gender?: UserGender;
  dateOfBirth?: string;
  createdAt: string;
  emailCodeHash?: string;
  emailCodeExpiry?: string;
  pictureUrl?: string;
  idType?: IdType;
  idNumber?: string;
  idCardUrl?: string;
  verified: boolean;
  verificationJobId?: string;
  verificationAttempt: number;
  verificationComplete?: boolean;
  verificationSummary?: string;
  walletAmount: number;
  rating: number;
  completedContractCount: number;
  isAdmin: boolean;
  temp: Record<string, any>;
}

export default User;

export enum UserStatus {
  NEW = "New",
  ACTIVE = "Active"
}

export enum UserGender {
  MALE = "Male",
  FEMALE = "Female"
}

export const USER_COLLECTION_NAME = "users";

export const USER_PICTURE_FILE_NAME = "picture.jpeg";
export const USER_ID_CARD_FILE_NAME = "card.jpeg";
