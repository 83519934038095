import Head from "next/head";
import { createContext, ReactNode } from "react";
import { useAuthContext } from "@/contexts/auth-context";
import { Button, Title } from "@/components/common";
import { GoAlert } from "react-icons/go";
import { APP_NAME } from "@/config/common";
import Link from "next/link";

interface AdminContext {}

const AdminContext = createContext<AdminContext>(null as never);

export const AdminProvider = (props: { children: ReactNode }) => {
  const { user } = useAuthContext();

  return (
    <AdminContext.Provider value={{}}>
      {user.isAdmin ? (
        props.children
      ) : (
        <>
          <Head>
            <title>Access Denied - {APP_NAME}</title>
          </Head>

          <div className="h-full grid place-items-center">
            <div className="flex flex-col gap-2 items-center">
              <div className="flex justify-between items-center gap-2 w-full max-w-[256px] px-4 py-2 border-2 border-black rounded-lg">
                <GoAlert />
                <Title align="center">Access Denied</Title>
                <GoAlert />
              </div>

              <Link href="/app">
                <Button>Back to App</Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </AdminContext.Provider>
  );
};
