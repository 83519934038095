import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";
import { Text } from "@/components/common/text";
import loadingImg from "@/assets/loading.svg";
import loaderWhiteImg from "@/assets/loading-white.svg";
import Image from "next/image";

interface SpinnerProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  color?: "white";
  inline?: boolean;
}

export const Spinner = forwardRef<HTMLDivElement, SpinnerProps>(
  ({ size = 32, className, children, color, inline, ...rest }: SpinnerProps, ref) => {
    const Root = inline ? "span" : "div";

    const _className = mergeClasses(
      inline ? "inline-flex" : "flex",
      "items-center",
      "justify-center",
      "gap-2",
      className
    );

    return (
      <Root ref={ref} className={_className} {...rest}>
        <Image src={color === "white" ? loaderWhiteImg : loadingImg} width={size} height={size} alt="loading" />
        {children && <Text>{children}</Text>}
      </Root>
    );
  }
);
