import User from "@/functions/src/models/user";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { onFirebaseAuthStateChanged, onFirestoreUserUpdated } from "@/utils/firebase";
import { Button, Spinner, Text } from "@/components/common";
import { Unsubscribe } from "@firebase/firestore";
import logoImg from "@/assets/logo.svg";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

interface AuthContext {
  user: User;
  authenticating: boolean;
  authenticated: boolean;
}

const AuthContext = createContext<AuthContext>(null as never);

export const useAuthContext = () => useContext(AuthContext);

const LOGOUT_DELAY = 5000; // 5s

export const AuthProvider = (props: { children: ReactNode }) => {
  const [authenticating, setAuthenticating] = useState(true);
  const [authUserUid, setAuthUserUid] = useState<string>();
  const [user, setUser] = useState<User>();
  const [showLogout, setShowLogout] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const unsub = onFirebaseAuthStateChanged((authUser) => {
      if (authUser) {
        setAuthUserUid(authUser.uid);
      } else {
        setAuthenticating(false);
        setAuthUserUid(undefined);
      }
    });

    setTimeout(() => {
      // Show logout notice after LOGOUT_DELAY
      setShowLogout(true);
    }, LOGOUT_DELAY);

    return () => {
      console.log("Unsubscribing!");
      if (unsub) unsub();
    };
  }, []);

  useEffect(() => {
    let unsub: Unsubscribe | undefined;

    if (authUserUid) {
      // When the user is updated in Firestore, update here also for rest of app
      unsub = onFirestoreUserUpdated(authUserUid, setUser);
      console.log("Subscribing");
    }

    return () => {
      console.log("Unsubscribing!");
      if (unsub) unsub();
    };
  }, [authUserUid]);

  return (
    <AuthContext.Provider value={{ user: user as never, authenticating, authenticated: !!user }}>
      {user ? (
        props.children
      ) : (
        <div className="h-full flex flex-col justify-center items-center gap-4">
          <div className="flex flex-col items-center gap-4">
            <Image src={logoImg} width={288} alt="App logo" />
            <Spinner size={16}>Authenticating. Please wait...</Spinner>
          </div>

          {showLogout && (
            <div className="flex flex-col items-center gap-4">
              <Text align="center">This is taking a bit longer than usual.</Text>
              <Link href={`/login?next=${encodeURIComponent(router.asPath)}`}>
                <Button>Login Again</Button>
              </Link>
            </div>
          )}
        </div>
      )}
    </AuthContext.Provider>
  );
};
