import { AnchorHTMLAttributes, forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";
import { useRouter } from "next/router";
import homeImg from "@/assets/home.svg";
import signImg from "@/assets/sign.svg";
import paymentImg from "@/assets/payment.svg";
import infoImg from "@/assets/info.svg";
import eventsImg from "@/assets/events.svg";
import Image from "next/image";
import { Text } from "@/components/common/text";
import Link from "next/link";

interface BottomNavProps extends HTMLAttributes<HTMLDivElement> {
  layout: "general" | "contract";
  active: "home" | "contracts" | "contract-details" | "contract-events" | "contract-payouts";
}

export const BottomNav = forwardRef<HTMLDivElement, BottomNavProps>(
  ({ layout, active, className, ...rest }: BottomNavProps, ref) => {
    const router = useRouter();
    const contractId = router.query.id as string;

    const _className = mergeClasses("flex", "items-center", "justify-around", className);

    return (
      <div ref={ref} className={_className} {...rest}>
        {layout === "general" ? (
          <>
            <BottomNavLink href="/app" icon={homeImg} label="Home" active={active === "home"} />

            <BottomNavLink href="/app/contracts" icon={signImg} label="Contracts" active={active === "contracts"} />

            {/* <BottomNavLink href="/app/wallets" icon={walletImg} label="Wallets" active={active === "wallets"} /> */}
          </>
        ) : layout === "contract" ? (
          <>
            <BottomNavLink
              href={`/app/contracts/${contractId}`}
              icon={infoImg}
              label="Details"
              active={active === "contract-details"}
            />

            <BottomNavLink
              href={`/app/contracts/${contractId}/events`}
              icon={eventsImg}
              label="Events"
              active={active === "contract-events"}
            />

            <BottomNavLink
              href={`/app/contracts/${contractId}/payouts`}
              icon={paymentImg}
              label="Payouts"
              active={active === "contract-payouts"}
            />
          </>
        ) : null}
      </div>
    );
  }
);

interface BottomNavLinkProps extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href" | "children"> {
  active: boolean;
  href: string;
  label: string;
  icon: string;
}

const BottomNavLink = forwardRef<HTMLAnchorElement, BottomNavLinkProps>(
  ({ active, label, icon, className, ...rest }: BottomNavLinkProps, ref) => {
    const _className = mergeClasses(
      "flex",
      "items-center",
      "gap-2",
      "py-3",
      "sm:px-4",
      "border-black",
      "opacity-60",
      active && "border-b-2 !opacity-100",
      className
    );

    return (
      <Link ref={ref} className={_className} {...rest}>
        <Image src={icon} width={18} alt="icon" /> <Text>{label}</Text>
      </Link>
    );
  }
);
