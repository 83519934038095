import ReactWebcam from "react-webcam";
import { useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";
import idCutout from "@/assets/id-cutout.svg";
import selfieCutout from "@/assets/selfie-cutout.svg";
import { Button, Text } from "@/components/common";

interface WebcamProps {
  mode: "selfie" | "card";
  disabled?: boolean;
  onScreenshot?: (data: string | undefined) => void;
}

export const Webcam = ({ mode, disabled, onScreenshot }: WebcamProps) => {
  const webcamRef = useRef<ReactWebcam>(null);
  const webcamHolderRef = useRef<HTMLDivElement>(null);
  const [webcamHolderHeight, setWebcamHolderHeight] = useState(0);
  const [screenshotB64, setScreenshotB64] = useState<string>();
  const { cutout, facing } = useMemo(() => {
    const cutout = mode === "selfie" ? selfieCutout : idCutout;
    const facing = mode === "selfie" ? "user" : { exact: "environment" };
    return { cutout, facing };
  }, [mode]);

  const handleCapture = () => {
    if (webcamRef.current) {
      const data = webcamRef.current.getScreenshot();

      if (data) {
        setScreenshotB64(data);
        onScreenshot?.(data);
      }
    }
  };

  const handleRetake = () => {
    setScreenshotB64(undefined);
    onScreenshot?.(undefined);
  };

  useEffect(() => {
    if (webcamHolderRef.current) {
      const onWindowResize = () => {
        const aspectRatio = 4 / 3;
        const { width } = webcamHolderRef.current!.getBoundingClientRect();
        const height = width / aspectRatio;
        setWebcamHolderHeight(height);
      };

      onWindowResize();

      window.addEventListener("resize", onWindowResize);

      return () => {
        window.removeEventListener("resize", onWindowResize);
      };
    }
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <div
        ref={webcamHolderRef}
        className="relative w-full overflow-hidden flex flex-col justify-center"
        style={{ height: `${webcamHolderHeight}px` }}>
        {!screenshotB64 ? (
          <ReactWebcam
            ref={webcamRef}
            screenshotQuality={1}
            videoConstraints={{
              facingMode: facing
            }}
            screenshotFormat="image/jpeg"
            forceScreenshotSourceSize
            mirrored={mode === "selfie"}
          />
        ) : (
          <img className="w-full h-full object-cover" src={screenshotB64} alt="Screenshot" />
        )}

        <Image src={cutout} className="absolute top-0 left-0 w-full z-[2] aspect-[4/3]" alt="Cutout" />
      </div>

      {mode === "selfie" && !screenshotB64 && (
        <Text align="center" size="lg">
          Smile 😀
        </Text>
      )}

      {!screenshotB64 ? (
        <Button onClick={handleCapture} disabled={disabled}>
          Capture
        </Button>
      ) : (
        <Button variant="secondary" onClick={handleRetake} disabled={disabled}>
          Retake Picture
        </Button>
      )}
    </div>
  );
};
