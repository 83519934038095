import Country, { countryGhana } from "@/functions/src/models/country";
import ghImg from "@/assets/gh.svg";
import Image from "next/image";
import { Text } from "@/components/common";

interface FlagPillProps {
  country: Country;
}

export const FlagPill = ({ country }: FlagPillProps) => {
  const flagIcon = () => {
    if (country === countryGhana) return ghImg;
    return null;
  };

  return (
    <div className="inline-flex items-center justify-center gap-1 bg-black rounded-full p-1 px-2">
      <Image src={flagIcon()} alt={country.iso} />
      <Text size="xs" className="text-white leading-none">
        {country.iso}
      </Text>
    </div>
  );
};
