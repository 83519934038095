import React, { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

export interface ContainerProps extends HTMLAttributes<HTMLDivElement> {
  as?: "div" | "header" | "footer";
  wide?: boolean;
}

export const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, children, wide, as: Root = "div", ...rest }: ContainerProps, ref) => {
    const _className = mergeClasses("!w-full !mx-auto !px-8", !wide ? "!max-w-[640px]" : "!max-w-[720px]", className);

    return (
      <Root ref={ref} className={_className} {...rest}>
        {children}
      </Root>
    );
  }
);
