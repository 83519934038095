import "@/styles/globals.css";
import "@smastrom/react-rating/style.css";
import type { AppProps } from "next/app";
import { ReactNode } from "react";
import { Toaster } from "react-hot-toast";
import { MemoryProvider } from "@/contexts";
import { QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "@/config/react-query";
import Head from "next/head";

export default function App({ Component, pageProps }: AppProps) {
  const decorator = (Component as any).decorator as (page: ReactNode) => ReactNode;
  const page = <Component {...pageProps} />;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
        />
      </Head>

      <QueryClientProvider client={queryClient}>
        <Toaster position="bottom-center" />
        <MemoryProvider>{decorator?.(page) ?? page}</MemoryProvider>
      </QueryClientProvider>
    </>
  );
}
