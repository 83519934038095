import { forwardRef, HTMLAttributes } from "react";
import Image from "next/image";
import iconImg from "@/assets/icon.svg";
import iconWhiteImg from "@/assets/icon-white.svg";

interface AppIconProps extends Omit<HTMLAttributes<HTMLImageElement>, "placeholder"> {
  size?: number;
  white?: boolean;
}

export const AppIcon = forwardRef<HTMLImageElement, AppIconProps>(
  ({ size = 48, white, ...rest }: AppIconProps, ref) => {
    return <Image ref={ref} src={!white ? iconImg : iconWhiteImg} alt="App icon" width={size} {...rest} />;
  }
);
