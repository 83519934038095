import { useEffect } from "react";
import { auth, db, fn, googleAuthProvider, store } from "@/config/firebase";
import {
  onAuthStateChanged,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
  signOut,
  User as AuthUser
} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import User, { USER_COLLECTION_NAME } from "@/functions/src/models/user";
import { getDownloadURL, ref, uploadBytes, uploadString } from "@firebase/storage";
import {
  AdminFetchContractsArgs,
  AdminFetchContractsData,
  AdminFetchDisputeMessagesArgs,
  AdminFetchDisputeMessagesData,
  AdminFetchUsersArgs,
  AdminFetchUsersData,
  AdminSettleContractDisputeArgs,
  AdminSettleContractDisputeData,
  CancelContractArgs,
  CancelContractData,
  CompleteContractArgs,
  CompleteContractData,
  ConfirmContractPaymentArgs,
  ConfirmContractPaymentData,
  CreateContractArgs,
  CreateContractData,
  CreateContractDisputeArgs,
  CreateContractDisputeData,
  CreateContractDisputeMessageArgs,
  CreateContractDisputeMessageData,
  CreateContractDisputeMessagePicturesArgs,
  CreateContractDisputeMessagePicturesData,
  CreateContractPaymentArgs,
  CreateContractPaymentData,
  CreateContractPicturesArgs,
  CreateContractPicturesData,
  CreateWalletArgs,
  CreateWalletData,
  DeleteWalletArgs,
  DeleteWalletData,
  FetchContractArgs,
  FetchContractData,
  FetchContractDisputeMessagesArgs,
  FetchContractDisputeMessagesData,
  FetchContractsArgs,
  FetchContractsData,
  FetchPendingWithdrawalArgs,
  FetchPendingWithdrawalData,
  FetchRecentContractsArgs,
  FetchRecentContractsData,
  FetchWalletsArgs,
  FetchWalletsData,
  RequestWithdrawalArgs,
  RequestWithdrawalData,
  SearchVerifiedUserArgs,
  SearchVerifiedUserData,
  SendWalletOtpArgs,
  SendWalletOtpData,
  UpdateUserIdCardArgs,
  UpdateUserIdCardData,
  UpdateUserPictureArgs,
  UpdateUserPictureData,
  VerifyUserIdentityArgs,
  VerifyUserIdentityData
} from "@/functions/src";

// Auth
export const firebaseGoogleLogin = () => {
  return signInWithPopup(auth, googleAuthProvider);
};

export const firebasePhoneLogin = async (phone: string) => {
  if (!window.recaptchaVerifier) throw new Error("You must solve the reCAPTCHA first!");

  const result = await signInWithPhoneNumber(auth, phone, window.recaptchaVerifier);
  window.phoneConfirmationResult = result;
  return result;
};

export const firebaseConfirmPhoneLogin = async (code: string) => {
  if (!window.phoneConfirmationResult) throw new Error("You must call firebasePhoneLogin first!");
  return await window.phoneConfirmationResult.confirm(code);
};

export const firebaseLogout = () => {
  return signOut(auth);
};

export const onFirebaseAuthStateChanged = (observer: (authUser: AuthUser | null) => void) => {
  return onAuthStateChanged(auth, observer);
};

export const useFirebaseVisibleReCaptcha = (args: {
  containerId: string;
  onSolved: () => void;
  onExpired: () => void;
}) => {
  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, args.containerId, {
        size: "normal",
        callback: args.onSolved,
        "expired-callback": args.onExpired
      });

      window.recaptchaVerifier.render().catch((err) => {
        console.error("Recaptcha render error", err);
      });
    } catch (err) {
      console.error("Recaptcha setup error", err);
    }
  }, []);
};

// Firestore DB
export const onFirestoreUserUpdated = (userId: string, observer: (user: User) => void) => {
  const ref = doc(db, USER_COLLECTION_NAME, userId);
  return onSnapshot(ref as never, (snapshot) => {
    observer(snapshot.data() as User);
  });
};

// Firebase Storage
export const firebaseUploadBase64String = (path: string, b64String: string) => {
  // Remove the "data:image/jpeg;base64," prefix of the string
  if (b64String.includes(",")) b64String = b64String.split(",")[1];
  const docRef = ref(store, path);
  return uploadString(docRef, b64String, "base64");
};

export const firebaseUploadBytes = (path: string, bytes: Blob) => {
  const docRef = ref(store, path);
  return uploadBytes(docRef, bytes);
};

export const firebaseGetDownloadUrl = (pathOrUrl: string) => {
  if (pathOrUrl.startsWith("http")) return Promise.resolve(pathOrUrl);
  const docRef = ref(store, pathOrUrl);
  return getDownloadURL(docRef);
};

// Callable functions
export const firebaseUpdateUserIdCard = httpsCallable<UpdateUserIdCardArgs, UpdateUserIdCardData>(
  fn,
  "updateUserIdCard"
);
export const firebaseUpdateUserPicture = httpsCallable<UpdateUserPictureArgs, UpdateUserPictureData>(
  fn,
  "updateUserPicture"
);
export const firebaseVerifyUserIdentity = httpsCallable<VerifyUserIdentityArgs, VerifyUserIdentityData>(
  fn,
  "verifyUserIdentity"
);

export const firebaseFetchContracts = httpsCallable<FetchContractsArgs, FetchContractsData>(fn, "fetchContracts");
export const firebaseFetchRecentContracts = httpsCallable<FetchRecentContractsArgs, FetchRecentContractsData>(
  fn,
  "fetchRecentContracts"
);
export const firebaseSearchVerifiedUser = httpsCallable<SearchVerifiedUserArgs, SearchVerifiedUserData>(
  fn,
  "searchVerifiedUser"
);
export const firebaseCreateContract = httpsCallable<CreateContractArgs, CreateContractData>(fn, "createContract");
export const firebaseCreateContractPictures = httpsCallable<CreateContractPicturesArgs, CreateContractPicturesData>(
  fn,
  "createContractPictures"
);
export const firebaseFetchContract = httpsCallable<FetchContractArgs, FetchContractData>(fn, "fetchContract");
export const firebaseCreateContractPayment = httpsCallable<CreateContractPaymentArgs, CreateContractPaymentData>(
  fn,
  "createContractPayment"
);
export const firebaseConfirmContractPayment = httpsCallable<ConfirmContractPaymentArgs, ConfirmContractPaymentData>(
  fn,
  "confirmContractPayment"
);
export const firebaseCancelContract = httpsCallable<CancelContractArgs, CancelContractData>(fn, "cancelContract");
export const firebaseCompleteContract = httpsCallable<CompleteContractArgs, CompleteContractData>(
  fn,
  "completeContract"
);

export const firebaseCreateContractDispute = httpsCallable<CreateContractDisputeArgs, CreateContractDisputeData>(
  fn,
  "createContractDispute"
);
export const firebaseCreateContractDisputeMessage = httpsCallable<
  CreateContractDisputeMessageArgs,
  CreateContractDisputeMessageData
>(fn, "createContractDisputeMessage");
export const firebaseCreateContractDisputeMessagePictures = httpsCallable<
  CreateContractDisputeMessagePicturesArgs,
  CreateContractDisputeMessagePicturesData
>(fn, "createContractDisputeMessagePictures");
export const firebaseFetchDisputeMessages = httpsCallable<
  FetchContractDisputeMessagesArgs,
  FetchContractDisputeMessagesData
>(fn, "fetchContractDisputeMessages");

export const firebaseSendWalletOtp = httpsCallable<SendWalletOtpArgs, SendWalletOtpData>(fn, "sendWalletOtp");
export const firebaseCreateWallet = httpsCallable<CreateWalletArgs, CreateWalletData>(fn, "createWallet");
export const firebaseFetchWallets = httpsCallable<FetchWalletsArgs, FetchWalletsData>(fn, "fetchWallets");
export const firebaseDeleteWallet = httpsCallable<DeleteWalletArgs, DeleteWalletData>(fn, "deleteWallet");

export const firebaseFetchPendingWithdrawal = httpsCallable<FetchPendingWithdrawalArgs, FetchPendingWithdrawalData>(
  fn,
  "fetchPendingWithdrawal"
);
export const firebaseRequestWithdrawal = httpsCallable<RequestWithdrawalArgs, RequestWithdrawalData>(
  fn,
  "requestWithdrawal"
);

export const firebaseAdminFetchUsers = httpsCallable<AdminFetchUsersArgs, AdminFetchUsersData>(fn, "adminFetchUsers");
export const firebaseAdminFetchContracts = httpsCallable<AdminFetchContractsArgs, AdminFetchContractsData>(
  fn,
  "adminFetchContracts"
);
export const firebaseAdminFetchDisputeMessages = httpsCallable<
  AdminFetchDisputeMessagesArgs,
  AdminFetchDisputeMessagesData
>(fn, "adminFetchDisputeMessages");
export const firebaseAdminSettleContractDispute = httpsCallable<
  AdminSettleContractDisputeArgs,
  AdminSettleContractDisputeData
>(fn, "adminSettleContractDispute");
