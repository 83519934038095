import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

interface LinearProgressProps extends HTMLAttributes<HTMLDivElement> {
  show?: boolean;
}

export const LinearProgress = forwardRef<HTMLDivElement, LinearProgressProps>(
  ({ className, children, show, ...rest }: LinearProgressProps, ref) => {
    return (
      <div ref={ref} className={mergeClasses("h-1", "lg:h-0.5", "overflow-hidden", className)} {...rest}>
        {show && <div className={mergeClasses("w-full", "h-full", "bg-black", "origin-left", "animate-loader")} />}
      </div>
    );
  }
);
