import React from "react";
import { Container, ContainerProps } from "@/components/common/container";
import { Title } from "@/components/common/title";
import { Text } from "@/components/common/text";
import { mergeClasses } from "@/utils/common";
import { IoMdClose } from "react-icons/io";
import Link from "next/link";

interface BottomSheetProps extends Omit<ContainerProps, "title"> {
  title: string;
  subtitle?: string;
  back?: string | (() => void);
}

export const BottomSheet = ({ title, subtitle, className, children, back, ...rest }: BottomSheetProps) => {
  return (
    <Container
      className={mergeClasses("bg-white rounded-tl-2xl rounded-tr-2xl flex flex-col gap-4 py-4", className)}
      {...rest}>
      <div>
        <div className="flex items-center justify-between">
          <Title>{title}</Title>

          {typeof back === "string" ? (
            <Link href={back}>
              <button className="h-8 grid place-items-center">
                <IoMdClose />
              </button>
            </Link>
          ) : typeof back === "function" ? (
            <button className="h-8 grid place-items-center" onClick={back}>
              <IoMdClose />
            </button>
          ) : null}
        </div>

        {subtitle && <Text className="text-gray-600">{subtitle}</Text>}
      </div>

      {children}
    </Container>
  );
};
