import { HTMLAttributes, ReactNode } from "react";
import { mergeClasses } from "@/utils/common";

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  type?: "error" | "warning" | "success" | "info";
  icon?: ReactNode;
}

export const Alert = ({ className, children, type, icon, ...rest }: AlertProps) => {
  const _className = mergeClasses(
    "px-4",
    "py-4",
    "flex",
    "items-center",
    "gap-4",
    "rounded-lg",
    "font-[500]",
    type === "error" && "bg-red-400 text-white",
    type === "warning" && "bg-orange-400 text-white",
    type === "success" && "bg-green-600 text-white",
    type === "info" && "bg-sky-500 text-white",
    className
  );

  return (
    <div className={_className} {...rest}>
      {icon}
      <div className="flex-1">{children}</div>
    </div>
  );
};
