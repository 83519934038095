import { forwardRef, HTMLAttributes } from "react";
import { mergeClasses } from "@/utils/common";

interface TextProps extends HTMLAttributes<HTMLParagraphElement> {
  as?: "span" | "strong" | "li";
  size?: "xs" | "sm" | "lg";
  italic?: boolean;
  underline?: boolean;
  align?: "center" | "right" | "justify";
  weight?: "light" | "normal" | "medium" | "bold";
  inline?: boolean;
}

export const Text = forwardRef<HTMLParagraphElement, TextProps>(
  ({ as, size, className, italic, underline, align, weight, inline, ...rest }: TextProps, ref) => {
    const Comp = (inline ? "span" : as || "p") as any;

    const _className = mergeClasses(
      // "leading-tight",
      inline && "inline",
      !size && "text-[14px] lg:text-[15px]",
      size === "xs" && "text-[10px] lg:text-[12px]",
      size === "sm" && "text-[12px] lg:text-[14px]",
      size === "lg" && "text-[15px] lg:text-[19px]",
      italic && "italic",
      underline && "underline",
      align === "center" && "text-center",
      align === "right" && "text-right",
      align === "justify" && "text-justify",
      weight === "light" && "font-light",
      weight === "normal" && "font-normal",
      weight === "medium" && "font-medium",
      weight === "bold" && "font-bold",
      className
    );

    return <Comp ref={ref} className={_className} {...rest} />;
  }
);
