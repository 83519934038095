import { forwardRef, HTMLAttributes, useEffect, useState } from "react";
import { generateId, mergeClasses } from "@/utils/common";
import Image from "next/image";
import cross from "@/assets/cross.svg";
import { Button } from "@/components/common/button";
import { Overlay } from "@/components/common/overlay";
import { useRouter } from "next/router";

export type Picture = string | Promise<string> | { file: File; ext: string };

interface PictureTileProps extends Omit<HTMLAttributes<HTMLDivElement>, "onChange"> {
  src: Picture;
  mode?: "cover" | "contain";
  onRemove?: (picture: Picture) => void;
}

export const PictureTile = forwardRef<HTMLDivElement, PictureTileProps>(
  ({ src: picture, className, mode = "cover", onRemove, ...rest }: PictureTileProps, ref) => {
    const router = useRouter();
    const [previewId] = useState(generateId("picture_tile"));
    const [src, setSrc] = useState("");
    const [loading, setLoading] = useState(true);

    const _className = mergeClasses("relative", className);

    const removeClassName = mergeClasses(
      "absolute",
      "-top-2",
      "-right-2",
      "grid",
      "h-6",
      "w-6",
      "place-items-center",
      "rounded-full",
      "bg-red-400"
    );

    const containerClassName = mergeClasses(
      "w-full",
      "h-full",
      "overflow-hidden",
      "rounded-lg",
      "hover:cursor-pointer"
    );

    const imgClassName = mergeClasses(
      "w-full",
      "h-full",
      "object-center",
      mode === "contain" && "object-contain",
      mode === "cover" && "object-cover"
    );

    const showPreview = () => {
      void router.push({ query: { ...router.query, [previewId]: true } });
    };

    const closePreview = () => {
      const query = { ...router.query };
      delete query[previewId];
      void router.push({ query });
    };

    useEffect(() => {
      if (picture) {
        if (typeof picture === "string") {
          setSrc(picture);
          setLoading(false);
        } else if (picture instanceof Promise) {
          picture.then((src) => {
            setSrc(src);
            setLoading(false);
          });
        } else {
          const reader = new FileReader();

          reader.onload = (e) => {
            if (e.target?.result) {
              setSrc(e.target.result as string);
              setLoading(false);
            }
          };

          reader.readAsDataURL(picture.file);
        }
      }
    }, [picture]);

    return (
      <div ref={ref} className={_className} {...rest}>
        {loading ? (
          <div className="w-full h-full animate-pulse bg-gray-200" />
        ) : (
          <div className={containerClassName} onClick={showPreview}>
            <Image src={src} alt="picture" loading="lazy" width={100} height={100} className={imgClassName} />
          </div>
        )}

        {onRemove && (
          <button type="button" className={removeClassName} onClick={() => onRemove(picture)}>
            <Image src={cross} width={10} height={10} alt="Remove" />
          </button>
        )}

        <Overlay show={!!router.query[previewId]} placement="center" onClose={closePreview}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img src={src} className="w-full h-full object-contain object-center" alt="Preview" />

          <Button type="button" className="absolute bottom-4 right-4" onClick={closePreview}>
            <Image src={cross} width={10} height={10} alt="Remove" />
            Close
          </Button>
        </Overlay>
      </div>
    );
  }
);
