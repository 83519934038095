import { Children, FunctionComponent, HTMLAttributes, ReactNode, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import Link from "next/link";
import { mergeClasses } from "@/utils/common";

interface Tab {
  id: string;
  label: ReactNode;
  default?: boolean;
}

interface TabsProps extends HTMLAttributes<HTMLDivElement> {
  as?: "div" | FunctionComponent;
  query: string;
  tabs: Tab[];
}

export const Tabs = ({ as, query, tabs, className, children, ...rest }: TabsProps) => {
  const router = useRouter();
  const tabId = router.query[query] as string | undefined;
  const _children = Children.toArray(children);

  const activeChild = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.id === tabId);
    const tabIds = tabs.map((tab) => tab.id);
    if (activeTab) return _children[tabIds.indexOf(activeTab.id)];
  }, [tabId, tabs, _children]);

  const tabsClassName = mergeClasses("border-b-2", "border-gray-300", className);

  const TabRoot = as || "div";

  if (!tabs || tabs.length === 0) throw new Error("Tabs must have at least one tab");

  const tabLinkClassName = (tab: Tab) => {
    return mergeClasses(
      "inline-block",
      "px-3",
      "py-2",
      "-mb-[2px]",
      "border-b-2",
      "transition-colors",
      "border-transparent",
      "hover:border-b-black",
      tab.id === tabId && "text-lg",
      tab.id === tabId && "border-b-black"
    );
  };

  useEffect(() => {
    if (router.isReady && !tabId) {
      const defaultTab = tabs.find((tab) => tab.default) || tabs[0];
      void router.replace({ query: { ...router.query, [query]: defaultTab.id } });
    }
  }, [router.isReady, query, tabId]);

  return (
    <>
      <TabRoot className={tabsClassName} {...rest}>
        {tabs.map((tab) => (
          <Link key={tab.id} href={{ query: { ...router.query, [query]: tab.id } }} className={tabLinkClassName(tab)}>
            {tab.label}
          </Link>
        ))}
      </TabRoot>

      {activeChild}
    </>
  );
};
